<template>

  <div id="holiday" :class="{editing: isEdit}">
    <v-toolbar elevation="0">
      <v-spacer />
      <v-scroll-y-transition>
        <v-alert v-if="isEdit" dense type="info" class="mb-0" color="primary">
          編集モード中：変更内容はリアルタイムに適用されます
        </v-alert>
      </v-scroll-y-transition>
      <v-spacer />
      <v-switch v-model="isEdit" label="編集モード" hide-details=""></v-switch>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col class="d-flex">
          <v-card style="flex:1" class="cal">
            <v-card-title>その他の休日<popup-hint>カレンダークリックで休日を設定・解除が行えます</popup-hint></v-card-title>
            <v-divider />
            <v-card-text class="pt-0">
              <v-toolbar flat height="50">
                <v-toolbar-title v-if="calHoliday">{{ calHoliday.title }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items v-if="calHoliday">
                  <v-btn class="dense" text @click="setToday">今月</v-btn>
                  <v-btn fab text small color="" @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
                  <v-btn fab text small color="" @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-sheet height="460">
              <v-calendar class="fullCal" ref="calHoliday" v-model="focus" @change="updateCalendar" color="primary" :weekdays="weekdays" :events="holiday" :event-more="false" @click:day="eventClick">
                <template v-slot:day="{ weekday }">
                  <span v-if="isRegularClosed(weekday)" class="regularHoliday">定休日</span>
                </template>
                  <template v-slot:event="{}">
                    <span class="">休日</span>
                  </template>
                <template v-slot:day-label="{ day, future, present}">
                  <span :class="{passed: !future, today: present}">{{day}}</span>
                </template>
              </v-calendar>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
        <v-row>
          <v-col cols="12" sm="6" md="12">
            <v-card class="start-end">
              <v-card-title>サポート対応時間
                <popup-hint>公開希望時間の入力制限が施されます</popup-hint>
              </v-card-title>
              <v-divider />
              <v-card-text class="pt-0">
                <h4>開始時間</h4>
                <v-text-field :readonly="!isEdit" v-model="start" @change="sendDataToAPI()" :max="end" type="time" hide-details dense></v-text-field>
                <h4>終了時間</h4>
                <v-text-field :readonly="!isEdit" v-model="end" @change="sendDataToAPI()" :min="start" type="time" hide-details dense></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="12">
            <v-card>
              <v-card-title>定休日<popup-hint>チェックを入れた曜日は公開希望日の入力制限が施されます</popup-hint></v-card-title>
              <v-divider />
              <v-card-text class="pt-0">
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="月曜日" :value="1" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="火曜日" :value="2" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="水曜日" :value="3" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="木曜日" :value="4" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="金曜日" :value="5" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060" :readonly="!isEdit" v-model="regularClosed" label="土曜日" :value="6" hide-details dense></v-checkbox>
                <v-checkbox color="#e06060"  :readonly="!isEdit" v-model="regularClosed" label="日曜日" :value="0" hide-details dense></v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PopupHint from '../../components/PopupHint.vue'
import axios from '@/plugins/axios'

export default {
  components: { PopupHint },
  name: 'Holiday',

  data: () => ({
    ready: false,
    start: '',
    end: '',
    regularClosed: [],
    focus: '',
    holiday: [],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    isEdit: false
  }),
  methods: {
    isRegularClosed (weekday) {
      var result = this.regularClosed.indexOf(weekday)
      return (result !== -1)
    },
    eventClick (day) {
      // console.log(day)
      if (day.future && !this.isRegularClosed(day.weekday) && this.isEdit) {
        if (!this.isHoliday(day.date)) {
          this.addHoliday(day)
        } else {
          this.removeHoliday(day)
        }
      }
    },
    addHoliday (day) {
      this.holiday.push({
        start: day.date
      })
    },
    removeHoliday (day) {
      const index = this.holiday.findIndex((el) => el.start === day.date)
      console.info(index)
      this.holiday.splice(index, 1)
    },
    updateCalendar () {
    },
    setToday () {
      this.focus = ''
    },
    next () {
      this.calHoliday.next()
    },
    prev () {
      this.calHoliday.prev()
    },
    isHoliday (date) {
      var result = this.holiday.find(el => el.start === date)
      // console.info(result)
      return result
    },
    readDataFromAppSettings () {
      this.start = this.$store.getters.getAdminCompanyHoliday.start_office_hours
      this.end = this.$store.getters.getAdminCompanyHoliday.end_office_hours
      this.regularClosed = this.$store.getters.getAdminCompanyHoliday.weekly_holiday
      this.$store.getters.getAdminCompanyHoliday.regular_holiday.forEach((value) => {
        if (value !== '') {
          this.holiday.push({ start: value })
        }
      })
      // Avoid sending data on the first data set
      this.$nextTick(function () {
        this.$watch('regularClosed', function (newVal, oldVal) {
          this.sendDataToAPI()
        })
        this.$watch('holiday', function (newVal, oldVal) {
          this.sendDataToAPI()
        })
      })
    },
    sendDataToAPI () {
      const self = this
      const adminCompanyHolidayId = this.$store.getters.getAdminCompanyHoliday.id
      const formData = new FormData()
      formData.append('_method', 'PUT')
      formData.append('days_off_monday', (self.regularClosed.includes(1) ? '1' : '0'))
      formData.append('days_off_tuesday', (self.regularClosed.includes(2) ? '1' : '0'))
      formData.append('days_off_wednesday', (self.regularClosed.includes(3) ? '1' : '0'))
      formData.append('days_off_thursday', (self.regularClosed.includes(4) ? '1' : '0'))
      formData.append('days_off_friday', (self.regularClosed.includes(5) ? '1' : '0'))
      formData.append('days_off_saturday', (self.regularClosed.includes(6) ? '1' : '0'))
      formData.append('days_off_sunday', (self.regularClosed.includes(0) ? '1' : '0'))
      formData.append('days_off_other', self.holiday.map((row) => { return row.start }).join(','))
      formData.append('start_office_hours', self.start ? self.start : '')
      formData.append('end_office_hours', self.end ? self.end : '')
      axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/admin_company_holiday/${adminCompanyHolidayId}`, formData)
        .then(res => {
          console.log(res)
          self.$store.commit('setAdminCompanyHoliday', res.data)
          self.$store.commit('setFlashSuccess', '営業日設定を編集しました。')
        })
        .catch(err => {
          console.log(err)
          self.$store.commit('setFlashSuccess', '営業日設定の編集に失敗しました。')
        })
    }
  },
  computed: {
    calHoliday () {
      return this.ready ? this.$refs.calHoliday : null
    }
  },
  created () {
    this.readDataFromAppSettings()
  },
  mounted () {
    this.ready = true
  }
}
</script>

<style scoped lang="scss">

  ::v-deep .v-calendar-monthly{
    .v-btn--fab {
      z-index: 2!important;
    }
    .v-event{
      position: absolute!important;
      left: 0;
      right: 0;
      z-index: 0;
    }
    .v-event,
    .regularHoliday{
      // position: initial;
      background-color: #e06060!important;
      color: white!important;
      text-align: center;
      width: auto!important;
      height: 50%!important;
      aspect-ratio: 1/1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: auto;
      font-size: .8em;
    }
    .v-calendar-weekly__day-label{
      padding: 5% 0;
      margin:0;
    }

  }

  #holiday.editing{
    ::v-deep .v-calendar-monthly{
      .v-calendar-weekly__day.v-future{
        cursor: pointer;
      }
    }

  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .cal .v-card__text{
      padding: 0;
    }
  }

.regularHoliday,
.passed{
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.regularHoliday{
  z-index: 1;
  position: relative;
}
.passed{
  color: #aaa;
  cursor: default;
  &:before{
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.today{
  color: white;
  background-color: var(--v-primary-base);
  width: 22px;
  aspect-ratio: 1/1;
  display: inline-block;
  line-height: 22px;
  border-radius: 50%;
  &:before{
    background-color: rgba(39, 38, 38, 0.05);
  }
}

</style>
