import { render, staticRenderFns } from "./PopupHint.vue?vue&type=template&id=f789704c&scoped=true"
import script from "./PopupHint.vue?vue&type=script&lang=js"
export * from "./PopupHint.vue?vue&type=script&lang=js"
import style0 from "./PopupHint.vue?vue&type=style&index=0&id=f789704c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f789704c",
  null
  
)

export default component.exports